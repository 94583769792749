.homepage {
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: 'Roboto', sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  header {
    background-color: var(--secondary-color);
    padding: 2rem;
    text-align: center;
  }
  
  header h1 {
    color: white;
    font-size: 3rem;
    margin-bottom: 0.5rem;
  }
  
  header p {
    color: var(--primary-color);
    font-size: 1.2rem;
  }
  
  main {
    flex-grow: 1;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .intro, .features, .cta {
    margin-bottom: 3rem;
  }
  
  h2 {
    color: var(--primary-color);
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .features {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }
  
  .feature {
    background-color: var(--sidebar-bg);
    padding: 1.5rem;
    border-radius: 10px;
    flex: 1;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .feature:hover {
    transform: translateY(-5px);
  }
  
  .feature i {
    font-size: 3rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }
  
  .feature h3 {
    color: var(--secondary-color);
    margin-bottom: 0.5rem;
  }
  
  .cta {
    text-align: center;
  }
  
  .cta-button {
    display: inline-block;
    background-color: var(--primary-color);
    color: white;
    padding: 1rem 2rem;
    border-radius: 25px;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: var(--secondary-color);
  }
  
  footer {
    background-color: var(--sidebar-bg);
    padding: 1rem;
    text-align: center;
  }